import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';

import { container, swap, leftBox, imageWrapper } from './cover.module.scss';

const Cover = ({ isSwapped = false, titleValue }) => {
  const Intl = useIntl();

  return (
    <div className={`${container} ${isSwapped && swap}`}>
      <div className={leftBox}>
        <h4>{titleValue}</h4>
      </div>
      <StaticImage
        className={imageWrapper}
        width={745}
        height={400}
        quality={98}
        placeholder="blurred"
        src="../../../../images/en/gps-location-tracking/no-more-spreadsheets.png"
      />
    </div>
  );
};

Cover.propTypes = {
  isSwapped: PropTypes.bool,
};

export default Cover;
